@charset 'utf-8';

/**
 * Global styles (apply to every page on the site)
 */

:root {
  --family-sans-serif: 'Poppins', sans-serif;
  --family-secondary: 'Ubuntu', sans-serif;
}

// local files
@import './variables';
@import './bulma';
@import './helpers';
@import './main';

// files with global styles. TODO: migrate to the component level
@import './booking';
@import './search';
@import './react-day-picker';

// Fontawesome icons
$fa-font-path: '/static';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
// @import '~@fortawesome/fontawesome-free/scss/regular.scss';

$ig-blue: $link;
@import '~react-image-gallery/styles/scss/image-gallery.scss';
.image-gallery-icon {
  filter: drop-shadow(0 0px 4px #1a1a1a4d);
}

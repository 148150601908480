@import 'bulma/sass/utilities/initial-variables.sass';

// Fonts
$family-sans-serif: var(--family-sans-serif);
$family-secondary: var(--family-secondary);

// Colors
$purple: #7c54d8;
$pink: #ff4365;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$blue: #00d9c0;
$green: #2bb360;
$success: $green;
$primary: #289984;
$primary-lightest: #e6f5f4;
$link: $pink;
$link-invert: #ffffff;
$red: #d61d1d;

$grey-lightest: hsl(0, 0%, 95%);
$grey-light: $beige-light;
$grey: #767676;
$grey-dark: #393d44;
$grey-darker: #181818;
$black: #16171a;
$text: $grey-darker;

// Layouts
$widescreen-enabled: false;
$fullhd-enabled: false;

// Navbar
$navbar-box-shadow-color: hsl(0, 0%, 95%);
$navbar-breakpoint: $tablet;
$navbar-height: 5rem;
$navbar-dropdown-z: 300;
$navbar-item-color: $link;
$navbar-item-active-color: $link;
$navbar-dropdown-item-hover-color: $link;
$navbar-item-active-background-color: $white;
$navbar-item-hover-background-color: $navbar-item-active-background-color;
$navbar-item-img-max-height: 2rem;

// Tabs
$tabs-link-color: $link;
$tabs-link-hover-color: $link;
$tabs-link-hover-border-bottom-color: $link;
$tabs-border-bottom-width: 2px;

// Modal
$modal-content-width: auto;

// Radius
$box-radius: 5px;
$control-radius: 5px;
$message-header-radius: 5px;
$message-body-radius: 5px;

// Footer
$footer-padding: 3rem 1.5rem;
// $footer-background-color: none;

// Tooltip
$tooltip-max-width: 18rem;

$control-height: 2.25em;

@import 'bulma/sass/utilities/functions.sass';
@import 'bulma/sass/utilities/derived-variables.sass';
@import 'bulma/sass/utilities/mixins.sass';

// custom mixins
@import './mixins';

.listing-photo-bubbles {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);

  overflow: hidden;
  width: 112px;
  height: 18px;

  pointer-events: none;

  > div {
    padding-top: 4px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    transition: transform 0.3s linear;

    > span {
      width: 10px;
      height: 10px;

      flex-shrink: 0;

      border-radius: 99px;
      background: white;
      opacity: 0.5;
      margin: 0 3px;
      transition: transform 0.2s linear, opacity 0.2s linear;

      &.is-active {
        transform: scale(1.5, 1.5);
        opacity: 0.92;
      }
    }
  }
}

.listings-filter-bar {
  top: $navbar-height;
  left: 0;
  height: 3.5rem;
  padding: 0 1rem;
  position: fixed;
  width: 100%;
  z-index: 29;
  background: $white;
  display: flex;
  align-items: stretch;

  .filter-item {
    padding: 8px;
    padding-top: 9px;
    position: relative;

    flex: 0 0 auto;
    display: inline-flex;
    align-items: center;
  }
  .filter-item.mobile-filter-item {
    display: none;
  }

  @mixin mobile_controls {
    border-bottom: 2px solid $grey-lightest;

    .filter-item {
      display: none;
    }
    .filter-item.mobile-filter-item {
      display: inline-flex;

      .button {
        font-size: 15px;

        @include map_control_button;
      }
    }
  }
  @include mobile {
    @include mobile_controls;
  }
  &.mobile-controls {
    @include mobile_controls;
  }
}

#filter-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  z-index: 31;

  background: rgba(230, 230, 230, 0.36);

  transition: opacity 0.1s ease, visibility 0.1s ease;
  &.inactive {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
}
.filter-modal {
  position: absolute;
  top: 100%;
  left: 0;

  z-index: 3000;

  margin-top: 8px;
  width: 300px;
}

// General, global styling goes here

html {
  overflow-y: auto;
}

#__root {
  display: flex;
  align-items: stretch;
  flex-direction: column;

  min-height: 100vh;
}
main {
  flex: 1;
}

.navbar.is-link .navbar-brand {
  cursor: pointer;
}
.navbar.is-link .navbar-brand > a.navbar-item:focus,
.navbar.is-link .navbar-brand > a.navbar-item:hover,
.navbar.is-link .navbar-brand > a.navbar-item.is-active,
.navbar.is-link .navbar-brand .navbar-link:focus,
.navbar.is-link .navbar-brand .navbar-link:hover,
.navbar.is-link .navbar-brand .navbar-link.is-active {
  background-color: transparent !important;
  cursor: pointer;
}

.navbar-item__notif-dot {
  display: block;

  width: 8px;
  height: 8px;
  border-radius: 99px;
  background: $red;

  position: absolute;
  top: 25%;
  right: 4px;
}
.navbar.is-transparent .navbar-item__notif-dot {
  background: $white;
}

.is-circle {
  border-radius: 999px;
  overflow: hidden;
}

.is-rounded {
  border-radius: $radius-large;
  overflow: hidden;
}

.navbar {
  transition: all 0.3s ease-in-out;
  transition-property: background-color, color, box-shadow;
  @include mobile {
    min-height: 3.25rem;
    .control {
      .input.is-medium {
        font-size: 1rem;
      }
      .icon {
        font-size: 1rem !important;
      }
    }
  }
}

.navbar svg {
  height: $navbar-item-img-max-height;
  fill: $link;
}
.navbar.is-link svg {
  fill: $white;
}

.button-link {
  @include button-reset;
  outline: none;
  color: $link;
  font-size: 16px;
  cursor: pointer;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.grey-link {
  text-decoration: underline;
  color: $grey;

  &:hover,
  &:focus {
    color: $grey-dark;
  }
}

.thin-scrollbar {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  scrollbar-width: thin;
}

.shadowed {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.has-navbar-shadow {
  box-shadow: 0 2px 0 0 #f2f2f2;
}

// Hash links need some space above them so they aren't hidden by fixed header
.hash-link-anchor {
  display: block;
  position: relative;
  top: -$navbar-height - 1rem;
  visibility: hidden;
}
.hash-link {
  position: relative;

  .hash-link-anchor {
    position: absolute;
  }

  &:hover::before {
    font-size: 20px;
    content: '🔗';
    color: #bbb;
    left: -26px;
    position: absolute;
  }
}

.is-sticky {
  position: sticky;
}

.is-sticky-menu {
  position: sticky;
  top: $navbar-height + 3rem;
  bottom: 0;
  @include mobile {
    position: static;
  }
}

@mixin label-after-content {
  display: inline-block;
  color: #afafaf;
  margin-left: 8px;
  font-size: 80%;
  line-height: 1.5;
}
.is-private::after {
  @include label-after-content;
  content: '(private)';
}
.is-optional::after {
  @include label-after-content;
  content: '(optional)';
}
.is-required::after {
  @include label-after-content;
  content: '(required)';
}

.button-reset {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}

.sr-only {
  @include sr_only;
}

.field-row {
  @include tablet {
    display: flex;
    & > .field {
      margin-bottom: 0;
      margin-right: 1rem;
      &:last-child {
        margin-right: 0rem;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

// react-collapse module
.ReactCollapse--collapse {
  transition: height 300ms ease;
}

.fa-ws {
  margin-right: 5px;
}

.fa-ws-2 {
  margin-right: 10px;
}

.hstack {
  display: flex;
  & > * + * {
    margin-left: 0.5rem;
  }
  @include mobile {
    display: block;
    & > * + * {
      margin-left: 0;
      margin-top: 0.5rem;
    }
  }
}

.hstack-2 {
  display: flex;
  & > * + * {
    margin-left: 1rem;
  }
  @include mobile {
    display: block;
    & > * + * {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}
.hstack-2-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  & > * {
    margin: 0.5rem;
  }
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// NOTE: text nodes cannot be direct children of this element
//       e.g. this is valid: <button class="button is-awesome"><span>Text</span></button>
.button.is-awesome {
  // dark pink gradiant button
  border: none;
  color: $white;
  background: transparent;
  overflow: hidden;

  isolation: isolate; // fixes border-radius in Safari. https://stackoverflow.com/a/58283449

  * {
    z-index: 2;
    position: relative;
    transition: transform 0.3s ease-in-out;
  }
  &:hover * {
    transform: scale(1.05);
  }
  &::before {
    $offset: 50%;
    content: '';
    position: absolute;
    top: -$offset;
    left: -$offset;
    width: 100% + 2 * $offset;
    height: 100% + 2 * $offset;
    background: linear-gradient(180deg, #f10482 10%, #f06f13 90%);
    z-index: 1;
    transition: transform 0.3s ease-in-out;
  }
  &:hover::before {
    transform: scale(1.2) rotate(20deg);
  }
}
